<template>
    <div class="navigation__wrap menu200">
        <ul class="df">
            <li><router-link to="/life/" exact-active-class="active">건강관리</router-link></li>
            <!-- <li><router-link to="/life/meal" exact-active-class="active">식사관리</router-link></li>
            <li><router-link to="/life/walk" exact-active-class="active">산책관리</router-link></li>
            <li><router-link to="/life/dosage" exact-active-class="active">투약관리</router-link></li> -->
            <li><router-link to="/notice" exact-active-class="active">견주 전달사항</router-link></li>
            <!-- <li><router-link to="/life/notice2" exact-active-class="active">견주 전달사항</router-link></li> -->
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Life',
    components: {
    }
}
</script>