<template>
    <div class="navigation__wrap menu200">
        <ul class="df">
            <!-- <li><router-link to="/integrated/" exact-active-class="active">유치원관리</router-link></li> -->
            <li><router-link to="/integrated/" exact-active-class="active">워치 관리</router-link></li>
            <li><router-link to="/integrated/gateway" exact-active-class="active">중계기 관리</router-link></li>
            <!-- <li><router-link to="/integrated/vehicle" exact-active-class="active">차량관리</router-link></li> -->
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Integrated',
    components: {
    }
}
</script>