<template>
  <div class="container__wrap login__wrap">
    <div class="login df">
      <div class="login__background">
        <img src="~@/assets/images/login-background.png" alt="이미지" />
      </div>
      <div class="login__side df">
        <div class="content__wrap">
          <div class="logo__box">
            <img src="@/assets/images/login-logo.png" />
          </div>
          <div class="title__box">
            <h1>{{ $t('login-title') }}</h1>
            <ul class="text__list df">
              <li class="text">{{ $t('login-menu1') }}</li>
              <li class="text">{{ $t('login-menu2') }}</li>
              <li class="text">{{ $t('login-menu3') }}</li>
              <li class="text">{{ $t('login-menu4') }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="login__content">
        <div class="login__box">
          <div class="login-title">{{ $t('login-sub__title') }}</div>
          <div class="login-input">
            <ul class="input-list">
              <li>
                <el-input :placeholder="$t('id')" v-model="username" class="login__type"></el-input>
              </li>
              <li>
                <el-input :placeholder="$t('password')" v-model="password" class="login__type" show-password></el-input>
              </li>
              <li class="button__li">
                <button type="submit" @click="login" class="btn btn-submit">
                  {{ $t('login') }}
                </button>
              </li>
            </ul>
            <!-- <div class="login-input__line df">
              <div class="line__block">
                <el-checkbox v-model="mbAutoLogin">자동로그인</el-checkbox>
              </div>
            </div> -->
          </div>
          <div class="login-find df">
            <div class="login-find__inline">
              <router-link to="/login/register" class="link_login"> {{ $t('join') }}</router-link>
            </div>
            <!-- <div class="login-find__inline">
              <router-link to="/login/id_lost" class="link_login">아이디 찾기</router-link>
            </div>
            <div class="login-find__inline last">
              <router-link to="/login/password_lost" class="link_login">비밀번호찾기</router-link>
            </div> -->
          </div>
          <div class="login-copy">
            CORYRIGHT 2023 MAXKKOREA INC. all rights reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      mbAutoLogin: false,
      mbRememberId: "",
    };
  },
  methods: {
    login: function () {
      // 사용자 이름과 비밀번호를 가져온다.
      var username = this.username;
      var password = this.password;

      // 로그인 버튼을 클릭한다.
      document.getElementById("loginButton").click();
    },
    initUser() {
      this.$cookies.remove("accessToken");
      this.$cookies.remove("token_type");
      this.$cookies.remove("expire_Date");
    },
    async login() {
      try {
        const keepLogin = this.mbAutoLogin ? 1 : 0;
        const saveUserID = this.mbRememberId ? 1 : 0;
        const response = await this.$axios.post(process.env.VUE_APP_USERLOGIN, null, {
          params: {
            username: this.username,
            password: this.password,
            keepLogin: keepLogin,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        });

        console.log(response.data)
        if (response.data.access_token === 'join_wait') {
          this.messageOpen(
            this.$t('login-alert2'),
            "피치바잇",
          );
        }
        else if (response.data.access_token === 'reject') {
          this.messageOpen(
            this.$t('login-alert3'),
            "피치바잇",
          );
        }
        else {
          const accessToken = response.data.access_token;
          const tokenType = response.data.token_type;
          const expireDate = response.data.expire_date;

          this.$cookies.set("accessToken", accessToken);
          this.$cookies.set("token_type", tokenType);
          this.$cookies.set("expire_Date", expireDate);
          this.$cookies.set("keepLogin", keepLogin);
          this.$cookies.set("saveUserID", saveUserID);

          this.saveAuthentication();
        }
      } catch (error) {
        // this.saveAuthentication();
        this.messageOpen(
         this.$t('login-alert1'),
         this.$t('peachbite'),
        );
      }
    },
    async saveAuthentication() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_USERCHECK, {
          headers: {
            Authorization: `${this.$cookies.get(
              "token_type"
            )} ${this.$cookies.get("accessToken")}`,
            Accept: "application/json",
          },
          params: {
            keepLogin: this.$cookies.get("keepLogin"),
          },
        });

        const accessToken = response.data.access_token;
        const token_type = response.data.token_type;
        const expireDate = response.data.expire_Date;
        const user_id = response.data.user_id;
        const user_type = response.data.user_type;

        this.$cookies.set("accessToken", accessToken);
        this.$cookies.set("token_type", token_type);
        this.$cookies.set("expire_Date", expireDate);
        this.$cookies.set("user_id", user_id);
        this.$cookies.set("user_type", user_type);
        this.$cookies.set("keepLogin", this.$cookies.get("keepLogin"));
        this.$cookies.set("saveUserId", this.$cookies.get("saveUserId"));

        if (user_type == 6) {
          this.messageOpen(
            "관리자만 로그인 가능합니다.",
            "피치바잇",
            "Login"
          );
        }
        else if (user_type == 99) {
          this.$router.push({ name: "MasterIndex" });
        }
        else {
          this.$router.push({ name: "Index" });
        }
      } catch (error) {
        // this.$router.push({ name: "Index" });
        this.messageOpen("로그인 정보가 유효하지 않습니다.", "피치바잇", "Login");
      }
    },
    messageOpen(message, title, target) {
      this.$alert(message, title, {
        confirmButtonText: "확인",
        function() {
          this.$router.push({ name: target });
        },
      });
    },
  },
};
</script>
