<template>
  <div class="navigation__wrap menu300">
    <ul class="df">
      <li>
        <router-link to="/student" exact-active-class="active"
          >반려동물 관리</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Student",
  components: {},
};
</script>
