<template>
  <div class="navigation__wrap menu100">
    <ul class="df">
      <li>
        <router-link to="/attend/" exact-active-class="active"
          >출결관리 ({{ attend }})</router-link
        >
      </li>
      <!-- <li>
        <router-link to="/attend/pickup" exact-active-class="active"
          >픽업관리 ({{ pickup }})</router-link
        >
      </li> -->
      <!-- <li><router-link to="/attend/notice" exact-active-class="active">견주전달사항</router-link></li> -->
      <!-- <li><router-link to="/attend/statistics" exact-active-class="active">통계</router-link></li> -->
    </ul>
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "Attend",
  components: {},
  data() {
    return {
      attend: "",
      pickup:"",
      timestamp: "",
    };
  },
  mounted() {
    this.timestamp = new Date();
    this.timestamp = this.formatDate(this.timestamp);
    this.getAttendTotal();
    this.getPickupTotal();
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    getAttendTotal() {
      this.$axios
        .get("attend/total", {
          headers: {
            Authorization: `${this.$cookies.get(
              "token_type"
            )} ${this.$cookies.get("accessToken")}`,
            Accept: "application/json",
          },
          params: {
            timestamp: this.timestamp,
          },
        })
        .then((response) => {
          this.attend = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPickupTotal() {
      this.$axios
        .get("pickup/total", {
          headers: {
            Authorization: `${this.$cookies.get(
              "token_type"
            )} ${this.$cookies.get("accessToken")}`,
            Accept: "application/json",
          },
          params: {
            timestamp: this.timestamp,
          },
        })
        .then((response) => {
          this.pickup = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
