<template>
    <div class="navigation__wrap menu100">
        <ul class="df">
            <li><router-link to="/pay/" exact-active-class="active">원비관리</router-link></li>
            <!-- <li><router-link to="/pay/history" exact-active-class="active">납부내역</router-link></li>
            <li><router-link to="/pay/non_payment" exact-active-class="active">미납관리</router-link></li> -->
            <!-- <li><router-link to="/pay/statistics" exact-active-class="active">통계</router-link></li> -->
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Pay',
    components: {
    }
}
</script>